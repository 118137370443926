import React from "react";
// import whatsappIcon from "../../assets/images/whatsappicon.png"
import whatsappIcon from "../../assets/images/whatsappicon.png";


function Whatsapp() {
  const myStyle: any = {
    position: "fixed",
    bottom: "20px",
    right: "1%",
    zIndex: "9999999px",
    borderRadius: "50%",
    transitionDelay: "1s",
  };
  const whStyle = {
    width: "50px",
    height: "50px",
  };
  const phoneNumber = "";
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  return (
    <div style={myStyle}>
      <a
        target="blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=3530874449489`}
      >
        <div className="whatsappIcon">
          <img style={whStyle} src={whatsappIcon} alt=""></img>
        </div>
      </a>
    </div>
  );
}

export default Whatsapp;
