import { useLocation, useNavigate } from "react-router-dom";
// import Logo from "../config/logo-white.svg";
import Logo from "../assets/images/logoNew-removebg-preview.png";
import DynamicIcon from "./drower/dynamicIcon";
import "./drower/styles.scss";

import { Modal, Popover, Select, Switch, notification } from "antd";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { LuLogOut } from "react-icons/lu";
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import CounterModal from "../component/counterModal/counterModal";
import {
  addCounter,
  clearCounter,
  clearHoldProducts,
  clearProduct,
  isSoleTrader,
} from "../redux/slices/retailExpress";
import { logout } from "../redux/slices/userSlice";
import { GET, POST } from "../utils/apiCalls";
import PopoverProfile from "./drower/popover";
// import { Menu } from "./menu";
import flag from "../assets/images/Arabic.png";
import flag2 from "../assets/images/UK.png";
import flag3 from "../assets/images/mongoliyan.png";
import flag4 from "../assets/images/India.png";
import flag5 from "../assets/images/malay.png";
import i18n from "../language";

import { useTranslation } from "react-i18next";
import { Store } from "../redux/store";

const language: any = [
  {
    id: 1,
    value: "en",
    title: "English",
    flag: flag2,
  },
  {
    id: 2,
    value: "ar",
    title: "العربية",
    flag: flag,
  },
  {
    id: 3,
    value: "ml",
    title: "Монгол",
    flag: flag3,
  },
  {
    id: 4,
    value: "hi",
    title: "हिन्दी",
    flag: flag4,
  },
  {
    id: 5,
    value: "ms",
    title: "Melay",
    flag: flag5,
  },
];

function SideBar({ collapsedChane, collapsed }: any) {
  const { t } = useTranslation();
  const token = useSelector((state: any) => state?.User?.user?.token);
  const soleTrader = useSelector(
    (state: any) => state?.retailExpress?.SoleTrader
  );
  const { user } = useSelector((state: any) => state.User);
  const { counter } = useSelector((state: any) => state.retailExpress);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const id = Number(localStorage.getItem("id")) || 1;

  const [ledgerList, setLedgerList] = useState<any>([]);
  const [receiptModal, setReceiptModal] = useState(false);
  const [counterModal, setCounterModal] = useState(false);
  const [clockIn, setClockIn] = useState(counter?.id ? true : false);
  const [counterList, setCounterList] = useState([]);
  const [counterSearch, setCounterSearch] = useState("");
  const [counterDetails, setCounterDetails] = useState<any>({});
  const [btLoding, setBtLoding] = useState(false);
  const [logOutOpen, setLogOutOpen] = useState(false);
  const [expand, setexpand] = useState(0);

  const Menu = [
    {
      id: 1,
      name: (t as any)("sidebar.text"),
      path: "/auth/counter-sale",
      icone: "FiTag",
    },
    {
      id: 2,
      name: (t as any)("sidebar.text_1"),
      path: "/auth/product",
      icone: "HiOutlineShoppingBag",
    },
    {
      id: 3,
      name: (t as any)("sidebar.text_2"),
      path: "/auth/saleInvoice",
      icone: "TbFileInvoice",
    },
    // {
    //   name: (t as any)("sidebar.text_3"),
    //   path: "other-payment",
    //   icone: "BsCashCoin",
    // },
    {
      id: 4,
      name: (t as any)("sidebar.text_4"),
      path: "/auth/counters",
      icone: "FaDesktop",
    },
    {
      id: 8,
      name: (t as any)("sidebar.text_5"),
      path: "/auth/customer",
      icone: "PiUserDuotone",
    },
    {
      id: 9,
      name: (t as any)("sidebar.text_7"),
      path: "/auth/order",
      icone: "BsBoxSeam",
    },
    soleTrader
      ? {
          id: 6,
          name: (t as any)("sidebar.text_8"),
          path: "/auth/report",
          icone: "VscGraph",
        }
      : [
          {
            id: 6,
            name: (t as any)("sidebar.text_8"),
            path: "/auth/report",
            icone: "VscGraph",
          },
          {
            id: 10,
            name: (t as any)("sidebar.text_14"),
            path: "/auth/report",
            icone: "VscGraph",
          },
        ],
    {
      id: 7,
      name: (t as any)("sidebar.text_9"),
      path: "/auth/subscription",
      icone: "IoStarOutline",
    },
  ].flat();

  const decoded: any = jwtDecode(token);
  let currentDate = new Date();
  let subscriptionEndDate = new Date(decoded?.subscriptionExpiry);
  const fetchLedgers = async () => {
    try {
      const url =
        "account_master/getMyLedgers/" +
        user?.id +
        "/" +
        user?.staff?.companyid;
      const { data }: any = await GET(url, null);
      setLedgerList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStaffCounterList = async () => {
    try {
      let obj = {
        adminId: user.id,
        companyid: user?.staff?.companyid,
        query: counterSearch,
        page: 1,
        take: 10,
      };
      let url = `billing_counter/list`;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setCounterList(response.datas);
      }
    } catch (error) {
      console.error("------- error -", error);
    }
  };

  const fetchStaffDifineCounterDetails = async () => {
    try {
      let obj = {
        staffid: user?.staff?.id,
        adminId: user?.id,
        sDate: dayjs().format("YYYY-MM-DD"),
        companyid: user?.staff?.companyid,
      };
      let url = `counter_details/counter/details`;
      const response: any = await POST(url, obj);
      if (response.status) {
        setCounterDetails(response);
      }
    } catch (error) {
      console.error("------- error -", error);
    }
  };

  useEffect(() => {
    if (subscriptionEndDate <= currentDate) {
      console.error("--------- your subscription is expired");
    } else {
      fetchLedgers();
      fetchStaffCounterList();
      if (!counter?.id) {
        fetchStaffDifineCounterDetails();
      }
    }
  }, []);

  const logOut = () => {
    dispatch(isSoleTrader(false));
    dispatch(clearCounter({}));
    dispatch(clearProduct([]));
    dispatch(clearHoldProducts());
    dispatch(logout({}));
  };

  const fetchOpenData = async () => {
    try {
      let obj = {
        adminId: user?.id,
        companyid: user?.staff?.companyid,
        staffid: user?.staff?.id,
        sDate: new Date(),
      };
      let url = `counter_details/counter/open`;
      const response: any = await POST(url, obj);
      if (response?.status) {
        dispatch(addCounter(response?.data));
        setClockIn(response?.data?.id ? true : false);
      } else {
        dispatch(clearCounter(""));
        setClockIn(false);
      }
    } catch (error) {
      setClockIn(counter?.id ? true : false);
      console.error("------- error -", error);
    }
  };

  useEffect(() => {
    fetchOpenData();
    handleChangeLanguage();
  }, []);

  const handleChangeLanguage = (value?: any) => {
    const languageData = language.find((item: any) => item?.id == value);
    if (languageData) {
      i18n.changeLanguage(languageData.value);
      localStorage.setItem("language", languageData?.value);
      localStorage.setItem("id", languageData.id);
    } else {
      const languageData: any = localStorage.getItem("language");
      i18n.changeLanguage(languageData);
    }
  };

  return (
    // <div className="sideBar-Box">
    //   <div className="sideBar-logo">
    //     <img src={Logo} alt="" />
    //   </div>
    //   <div>
    //     <div className="sideBar-txt1">MAIN MENUS</div>
    //     {DrowerData?.map((item: any) => {
    //       return (
    //         <div
    //           key={item?.id}
    //           onClick={() => navigate(item?.navigate)}
    //           className={
    //             location.pathname === item?.navigate
    //               ? "sideBar-item active"
    //               : "sideBar-item"
    //           }
    //         >
    //           <DynamicIcon name={item?.icone} />
    //           <div style={{ marginLeft: 20 }} />
    //           <div>{item?.name}</div>
    //         </div>
    //       );
    //     })}
    //   </div>
    // </div>
    <>
      <div
        className="drawerScreen-logo"
        onClick={() => {
          // navigate('/Auth/counter-sale')
          // collapsedChane()
        }}
      >
        <img src={Logo} />
      </div>

      {collapsed ? (
        <Popover
          content={
            <PopoverProfile
              setexpand={(val: any) => setexpand(val)}
              logOut={() => {
                if (!counter?.id) {
                  logOut();
                } else {
                  setCounterModal(true);
                  setLogOutOpen(true);
                }
              }}
            />
          }
          trigger="hover"
          placement="rightTop"
        >
          <div
            className={"sidebar-Box1"}
            onClick={() => {
              setexpand(0);
              navigate("/auth/company");
            }}
          >
            <div className="drawe-image">
              {user?.companyInfo?.bimage ? (
                <img src={user?.companyInfo?.bimage} />
              ) : (
                <RxAvatar size={35} />
              )}
            </div>
            <div>
              <span className="DrawerBName">{user?.companyInfo?.bname}</span>
              <span>Staff</span>
            </div>
          </div>
        </Popover>
      ) : (
        <Popover
          content={
            <PopoverProfile
              setexpand={(val: any) => setexpand(val)}
              logOut={() => {
                if (!counter?.id) {
                  logOut();
                } else {
                  setCounterModal(true);
                  setLogOutOpen(true);
                }
              }}
            />
          }
          trigger="hover"
          placement="rightTop"
        >
          <div
            className="drawe-image2"
            onClick={() => {
              setexpand(0);
              navigate("/auth/company");
            }}
          >
            {user?.companyInfo?.bimage ? (
              <img src={user?.companyInfo?.bimage} />
            ) : (
              <RxAvatar size={37} />
            )}
          </div>
        </Popover>
      )}

      <div className="sidebar-container">
        {soleTrader === true ? null : (
          <div
            className={collapsed ? "sidebarClockIn " : ""}
            style={{ marginBottom: "20px" }}
            onClick={() => {
              if (subscriptionEndDate <= currentDate) {
                notification.error({
                  message: "Expired",
                  description: "Your subscription is expired",
                });
                console.error("--------- your subscription is expired");
              } else {
                setCounterModal(true);
                setClockIn(!clockIn);
              }
            }}
          >
            {collapsed ? (
              <div>
                {clockIn
                  ? (t as any)("sidebar.text_10")
                  : (t as any)("sidebar.text_11")}
                &nbsp;&nbsp;
                <Switch
                  checked={
                    Store?.getState()?.retailExpress?.counter?.id
                      ? true
                      : clockIn
                  }
                  loading={btLoding}
                />
              </div>
            ) : (
              <Popover
                content={
                  clockIn
                    ? (t as any)("sidebar.text_10")
                    : (t as any)("sidebar.text_11")
                }
                trigger="hover"
                placement="right"
              >
                <Switch
                  checked={
                    Store?.getState()?.retailExpress?.counter.id
                      ? true
                      : clockIn
                  }
                  loading={btLoding}
                />
              </Popover>
            )}
          </div>
        )}

        <div className="drawerContent">
          {!collapsed ? (
            <Popover content={"Show more"} trigger="hover" placement="right">
              <div
                className="collapse-btn mb-3"
                onClick={() => collapsedChane()}
              >
                <GoArrowRight size={24} color="#18a762" />
              </div>
            </Popover>
          ) : (
            <div className="SiderBar-txt1">
              {(t as any)("sidebar.text_12")}
              <Popover content={"Show less"} trigger="hover" placement="right">
                <div className="collapse-btn" onClick={() => collapsedChane()}>
                  <GoArrowLeft size={24} />
                </div>
              </Popover>
            </div>
          )}
          {Menu.map((item: any) => {
            return collapsed ? (
              <div
                className="draweText"
                style={{ backgroundColor: item.id == expand ? "#b4ffdb" : "" }}
                onClick={() => {
                  setexpand(item.id);
                  item.path == "other-payment"
                    ? setReceiptModal(true)
                    : navigate(item.path);
                }}
              >
                <DynamicIcon
                  name={item.icone}
                  size={20}
                  color={item.id == 7 ? "#ffd700" : "#18a762"}
                />
                <div style={{ marginLeft: "15px" }}>{item.name}</div>
              </div>
            ) : (
              <Popover content={item.name} trigger="hover" placement="right">
                <div
                  className="draweText"
                  style={{
                    backgroundColor: item.id == expand ? "#b4ffdb" : "",
                  }}
                  onClick={() => {
                    localStorage.setItem("expand", item.id);
                    item.path == "other-payment"
                      ? setReceiptModal(true)
                      : navigate(item.path);
                  }}
                >
                  <DynamicIcon
                    name={item.icone}
                    size={20}
                    color={item.id == 7 ? "#ffd700" : "#18a762"}
                  />
                </div>
              </Popover>
            );
          })}
        </div>
        <div className="drawerContent-2">
          {!collapsed ? null : (
            <div className="SiderBar-txt2">
              <DynamicIcon name={"IoLanguageOutline"} size={20} />
              &nbsp; {(t as any)("sidebar.text_13")}
            </div>
          )}
          {collapsed ? (
            <div>
              {/* <DynamicIcon name={'IoLanguageOutline'} size={20} /> */}
              {/* <div style={{ marginLeft: '15px' }}>{'item.name'}</div> */}
              {/* <div><DropdownLanguage /></div> */}
              <Select
                style={{ width: "100%" }}
                bordered={false}
                defaultValue={id}
                onSelect={(val: any) => handleChangeLanguage(val)}
              >
                {language.map((lang: any) => (
                  <Select.Option value={lang.id} key={lang.id}>
                    <img
                      style={{
                        width: "30px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      src={lang.flag}
                    />
                    {lang.title}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : null}
        </div>

        {/* <div>
          <div className={collapsed ? 'draweText2' : 'logout-box'} onClick={() => {
            if (!counter?.id) {
              logOut()
            } else {
              setCounterModal(true);
              setLogOutOpen(true)
            }
          }}>
            {collapsed ? (
              <>
                <DynamicIcon name={'LuLogOut'} size={20} color={'red'} />
                <div style={{ marginLeft: '15px' }}>Log Out</div>
              </>
            ) : (
              // <Popover content={'Log Out'} trigger="hover" placement="right">
              // <DynamicIcon name={'LuLogOut'} size={20} color={'red'} />
              // </Popover>
              <Popover content={'Log Out'} trigger="hover" placement="right">
                <div>
                  <LuLogOut size={20} color={'red'} />
                </div>
              </Popover>
            )}
          </div>
          <div className={'draweStaff-Box1'} onClick={() => {
          setexpand(0)
          navigate('/Auth/company')
        }}>
        </div> */}
        {/* </div> */}
      </div>
      {counterModal ? (
        <Modal open={counterModal} width={700} closable={false} footer={false}>
          <CounterModal
            counterList={counterList}
            counterSearch={(val: any) => setCounterSearch(val)}
            onClose={() => setCounterModal(false)}
            counterDetails={counterDetails?.data?.counter}
            setClockIn={() => setClockIn(counter?.id ? true : false)}
            logOut={() => logOut()}
            logOutOpen={logOutOpen}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default SideBar;
