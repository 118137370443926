import dayjs from "dayjs";
import { useSelector } from "react-redux";
import NoData from "../../../component/nodata";
import '../styles.scss';
import { useTranslation } from "react-i18next";
function ReportList({ list, onSelect, shiftId }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);

    return (
        list.length ? (
            list?.map((item: any) => (
                <div className='reportList' style={{ backgroundColor: shiftId == item.id ? '#b4ffdb' : '#fff' }} onClick={() => onSelect(item.id)}>
                    <div className="listBox">
                        <div>
                            <div className="lestText">{item?.shift_type?.toUpperCase()}</div>
                            <div className="lestText2">{item?.open_denomination?.time || '_ _ _ _'} To {item?.close_denomination?.time || '_ _ _ _'}</div>
                        </div>
                        <div>
                            <div className="lestText3">Opening</div>
                            <div className="lestText4">{item?.open_denomination?.total_balance || '_ _ _ _'}</div>
                        </div>
                    </div>
                    <div className="listBox2" style={{ textAlign: 'center' }}>
                        <div className="lestText3">Closing</div>
                        <div className="lestText4">{item?.close_denomination?.total_balance || '_ _ _ _'}</div>
                    </div>
                    <div className="listBox2" style={{ textAlign: 'center' }}>
                        <div className="lestText3">Invoices</div>
                        <div className="lestText4">{item?.saleCount || '_ _ _ _'}</div>
                    </div>
                    <div className="listBox" style={{ textAlign: 'end' }}>
                        <div className="listDate">{dayjs(item?.sdate).format('YYYY-MM-DD')} </div>
                        <div>
                            <div className="lestText3">Total</div>
                            <div className="lestText4">{user?.companyInfo?.countryInfo?.symbol} {item?.saleTotal || '0'}</div>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <NoData text={(t as any)("report.text_16")} />
        )

    )
}

export default ReportList